
import { defineComponent } from "vue";
import CustomIcon from "./CustomIcon.vue";

export default defineComponent({
  name: "FolderListCard",
  components: { CustomIcon },
  props: ["folder"],
  setup (props, { emit }) {
    const selectFolder = (folderId) => {
      emit("selectFolder", folderId);
    };
    return {
      selectFolder,
    };
  },
});
